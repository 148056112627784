/* eslint-disable @typescript-eslint/no-explicit-any */

import { array, map, option } from 'fp-ts/es6';
import { eqString } from 'fp-ts/es6/Eq';
import { toNullable } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/pipeable';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import {
  AssetState,
  CMContainer,
  DeviceLocation,
  GeofenceEvent,
  GlobalProjectId,
  LocalBeacons,
  MessageType,
  SensedAssetsReport,
  SensedTemp,
  SensedTriggeredEvent,
  TempRangeEvent,
  WelfareCheckResponse,
} from '../../Components/Map/Messages';
import {
  LIVE_LATEST_EVENTS,
  LIVE_NEW_MESSAGES,
  LIVE_NEW_NOTIFICATIONS,
} from '../../store/liveEvents';
import { isDefined } from '../../util/isDefined';
//import produce, { Draft } from 'immer'
import {
  AssetUpdateType,
  LiveMapUpdate,
  updateFenceEvent,
  updateFullState,
  updateLocalBeacons,
  updateLocation,
  updateNotification,
  updateSensedAssets,
  updateSensedTemp,
  updateSensedTriggered,
  updateTempRangeEvent,
  updateWelfareCheckResponse,
} from './LiveMapActions';
import { localiseAssetId } from './LiveMapRenderer';
import { LIVE_NOTIFICATIONS, RawNotification, makeNotificationId } from '../../store/notifications';
import { uniqBy } from 'lodash';
import { useAugmentedToolLabel } from '../../util/useToolsLabel';
import { TRACKING_BOUNDS } from '../../Components/Map/MapDefaults';

function emptyAssetState(
  assetId: string,
  lastLocation: option.Option<DeviceLocation> = option.none,
  lastFenceEvent: option.Option<GeofenceEvent> = option.none,
  lastBeacons: option.Option<SensedAssetsReport> = option.none,
  lastLocalBeacons: option.Option<LocalBeacons> = option.none,
  lastTemp: option.Option<SensedTemp> = option.none,
  lastTempRangeEvent: option.Option<TempRangeEvent> = option.none,
  lastWelfareCheckResponse: option.Option<WelfareCheckResponse> = option.none,
  recentSensedTriggered: option.Option<SensedTriggeredEvent[]> = option.none,
): AssetState {
  return {
    id: {},
    label: assetId,
    lastLocation,
    lastFenceEvent,
    lastSensed: lastBeacons,
    lastLocalBeacons,
    lastTemp,
    lastTempRangeEvent,
    lastWelfareCheckResponse,
    recentSensedTriggered,
  };
}

export interface LiveMapState {
  assets: Map<string, AssetState>;
  selectedAsset: option.Option<string>;
  notifications: RawNotification[];
}

export const nonImmerReducer = (lms: LiveMapState, u: LiveMapUpdate): LiveMapState => {
  if (u.type === AssetUpdateType.FullStateUpdate) {
    return u.payload;
  }
  const aid =
    u.payload.assetId === undefined && u.type === AssetUpdateType.LocalBeacons
      ? localiseAssetId(u.payload.deviceLocation.assetId)
      : u.payload.assetId;
  if (!aid) {
    return lms;
  }
  const toUpdate = pipe(
    option.fromNullable(lms.assets.get(aid)),
    option.getOrElse(() => emptyAssetState(aid)),
  );
  const mapInsert = map.insertAt(eqString);
  switch (u.type) {
    case AssetUpdateType.Location: {
      const ul: AssetState = {
        ...toUpdate,
        id: u.payload.id,
        sourceId: u.payload.sourceId,
        lastLocation: option.some(u.payload),
      };
      return { ...lms, assets: mapInsert(aid, ul)(lms.assets) };
    }

    case AssetUpdateType.GeofenceEvent: {
      const ug: AssetState = {
        ...toUpdate,
        id: u.payload.id,
        lastFenceEvent: option.some(u.payload),
      };
      return { ...lms, assets: mapInsert(aid, ug)(lms.assets) };
    }

    case AssetUpdateType.SensedAssets: {
      const ub: AssetState = {
        ...toUpdate,
        id: u.payload.id,
        lastSensed: option.some(u.payload),
      };
      return { ...lms, assets: mapInsert(aid, ub)(lms.assets) };
    }

    case AssetUpdateType.SensedTriggered: {
      const previousSensedTriggered = toNullable(toUpdate.recentSensedTriggered) ?? [];
      const ub: AssetState = {
        ...toUpdate,
        id: u.payload.id,
        recentSensedTriggered: option.some(
          [
            u.payload,
            ...previousSensedTriggered.filter(
              st => JSON.stringify(st.sensedId) !== JSON.stringify(u.payload.sensedId),
            ),
          ].sort(
            ({ timestamp: a }, { timestamp: b }) => new Date(b).getTime() - new Date(a).getTime(),
          ),
        ),
      };
      return { ...lms, assets: mapInsert(aid, ub)(lms.assets) };
    }

    case AssetUpdateType.LocalBeacons: {
      const localBeacons: AssetState = {
        ...toUpdate,
        id: u.payload.id,
        lastLocalBeacons: option.some(u.payload),
      };
      return { ...lms, assets: mapInsert(aid, localBeacons)(lms.assets) };
    }

    case AssetUpdateType.SensedTemp: {
      const ut: AssetState = {
        ...toUpdate,
        id: u.payload.id,
        lastTemp: option.some(u.payload),
      };
      return { ...lms, assets: mapInsert(aid, ut)(lms.assets) };
    }

    case AssetUpdateType.TempRangeEvent: {
      const ur: AssetState = {
        ...toUpdate,
        id: u.payload.id,
        lastTempRangeEvent: option.some(u.payload),
      };
      return { ...lms, assets: mapInsert(aid, ur)(lms.assets) };
    }

    case AssetUpdateType.WelfareCheckResponseUpdate: {
      const wc: AssetState = {
        ...toUpdate,
        id: u.payload.id,
        lastWelfareCheckResponse: option.some(u.payload),
      };
      return { ...lms, assets: mapInsert(aid, wc)(lms.assets) };
    }

    case AssetUpdateType.NotificationUpdate: {
      const nextNotification: RawNotification = {
        ...u.payload,
        datetime: new Date(u.payload.timestamp),
      };
      return {
        ...lms,
        notifications: uniqBy([...lms.notifications, nextNotification], 'notificationId'),
      };
    }
  }
};

export function toMapUpdateOrUndefined(
  o: CMContainer,
  labeller: (
    id: Record<string, string | undefined>,
    label: string | undefined,
    fallback?: string | undefined,
  ) => string | undefined,
): LiveMapUpdate | LiveMapUpdate[] | undefined {
  const m = (o as any).data;
  switch (o.type) {
    case MessageType.Coordinates:
      return updateLocation({
        id: m.ids.id,
        assetId: m.ids.label,
        label: labeller(m.ids.id, m.ids.label) ?? m.ids.label,
        isBeacon: !!(m.ids.id.uuid || m.ids.id.minor || m.ids.id.major),
        sourceId: m.sourceIds?.label,
        timestamp: m.iso8601,
        lon: m.coordinates.longitude,
        lat: m.coordinates.latitude,
        radius: m.coordinates.errorRadiusMetres,
      });
    case MessageType.CoordinatesDwell:
      return updateFenceEvent({
        id: m.ids.id,
        assetId: m.ids.label,
        timestamp: m.iso8601,
        fenceId: m.fenceIds.id,
        fenceName: m.fenceIds.label,
        type: m.fenceIds.type,
        layerId: m.layerIds.id,
        x: m.coordinates.longitude,
        y: m.coordinates.latitude,
        event: 'Dwell',
        dwellSeconds: m.dwellSeconds,
      });
    case MessageType.CoordinatesTriggered:
      if (m.layerIds.label === TRACKING_BOUNDS && !m.entered) {
        return [
          updateFenceEvent({
            id: m.ids.id,
            assetId: m.ids.label,
            timestamp: m.iso8601,
            fenceId: m.fenceIds.id,
            fenceName: m.fenceIds.label,
            type: m.fenceIds.type,
            layerId: 'n/a',
            x: m.coordinates.longitude,
            y: m.coordinates.latitude,
            event: m.entered ? 'Entered' : 'Exited',
          }),
          updateNotification({
            id: m.ids.id,
            notificationId: makeNotificationId({
              type: 'CoordinatesTriggered',
              iso8601: m.iso8601,
              primaryId: JSON.stringify(m.ids.id),
            }),
            assetId: m.ids.label,
            label: `${labeller(
              m.fenceIds.id,
              m.fenceIds.label,
              'Asset ' + m.ids.label + ' exited a tracking bound',
            )}`,
            timestamp: m.iso8601,
          }),
        ];
      }

      return updateFenceEvent({
        id: m.ids.id,
        assetId: m.ids.label,
        timestamp: m.iso8601,
        fenceId: m.fenceIds.id,
        fenceName: m.fenceIds.label,
        type: m.fenceIds.type,
        layerId: 'n/a',
        x: m.coordinates.longitude,
        y: m.coordinates.latitude,
        event: m.entered ? 'Entered' : 'Exited',
      });
    case MessageType.Sensed:
      return updateSensedAssets({
        id: m.ids.id,
        assetId: m.ids.label,
        timestamp: m.iso8601,
        assets: m.sensed.map((sensed: any) => ({
          label: labeller(sensed.ids.id, sensed.ids.label),
          id: sensed.ids.id,
          uuid: sensed.ids.id.uuid,
          major: sensed.ids.id.major,
          minor: sensed.ids.id.minor,
          rssi: sensed.rssi,
          txpower: sensed.txPower,
          distance: sensed.distanceMetres,
          battery_level: NaN,
        })),
      });
    case MessageType.SensedTriggered:
      return [
        updateSensedTriggered({
          id: m.ids.id,
          assetId: m.ids.label,
          sensedId: m.sensedIds.id,
          sensedLabel: labeller(m.sensedIds.id, m.sensedIds.label),
          timestamp: m.iso8601,
          entered: m.entered,
        }),
        updateNotification({
          id: m.ids.id,
          notificationId: makeNotificationId({
            type: 'SensedTriggered',
            iso8601: m.iso8601,
            primaryId: JSON.stringify(m.ids.id),
            otherIds: [JSON.stringify(m.sensedIds.id)],
          }),
          assetId: m.ids.label,
          label: `${labeller(m.sensedIds.id, m.sensedIds.label, 'Asset ' + m.sensedIds.label)} ${
            m.entered ? 'entered' : 'exited'
          } MicroFence ${m.ids.label}`,
          timestamp: m.iso8601,
          interaction: {
            microfenceId: m.ids.id,
            microfenceName: m.ids.label,
            type: 'find-microfence',
          },
        }),
      ];
    case MessageType.Temperature:
      return updateSensedTemp({
        id: m.ids.id,
        assetId: m.ids.label,
        timestamp: m.iso8601,
        temp: m.celsius,
      });
    case MessageType.SensorTriggeredBoundary:
      if (m.boundaryIds.type === 'TEMPERATURE_CELSIUS')
        return updateTempRangeEvent({
          id: m.ids.id,
          temp: m.value,
          event: m.entered ? 'Entered' : 'Exited',
          limit: NaN,
          assetId: m.ids.label,
          timestamp: m.iso8601,
          tempRangeId: m.boundaryIds.id,
          tempRangeLabel: m.boundaryIds.label,
        });
      else return;
    case MessageType.Battery:
    case MessageType.Spo2:
      return;
    default:
      console.warn('Unknown message from websocket', o);
  }
}

export interface AuthPacket {
  gpid: GlobalProjectId;
  login: string;
  key: string;
}

export interface LiveStreamData {
  state: LiveMapState;
  lastUpdates: option.Option<LiveMapUpdate[]>;
  startDate: Date;
}

const INITIAL_STATE: LiveMapState = {
  assets: new Map<string, AssetState>(),
  selectedAsset: option.none,
  notifications: [],
};

export function useGeomobyLiveStream({
  useFor,
}: {
  useFor: 'notifications' | 'livemap';
}): LiveStreamData {
  const startDate = useMemo(() => new Date(), []);
  const [lmState, lmDispatch] = useReducer(nonImmerReducer, INITIAL_STATE);
  const [lastLMU, setLMU] = useState<option.Option<LiveMapUpdate[]>>(option.none);

  const lastEvents = useAtomValue(LIVE_LATEST_EVENTS);
  const setNotifications = useSetAtom(LIVE_NOTIFICATIONS);
  const isWantedUpdate = useCallback(
    (u: LiveMapUpdate) =>
      useFor === 'notifications'
        ? u.type === AssetUpdateType.NotificationUpdate
        : u.type !== AssetUpdateType.NotificationUpdate,
    [useFor],
  );
  const augmentLabelIfKnown = useAugmentedToolLabel();

  useEffect(() => {
    if (lastEvents) {
      const msgs = lastEvents
        .flatMap(e => toMapUpdateOrUndefined(e, augmentLabelIfKnown))
        .filter(isDefined);
      const actualInitialState = array.reduce<LiveMapUpdate, LiveMapState>(
        INITIAL_STATE,
        (b: LiveMapState, a: LiveMapUpdate) => (isWantedUpdate(a) ? nonImmerReducer(b, a) : b),
      )(msgs);
      const updateEvent = updateFullState(actualInitialState);
      lmDispatch(updateEvent);
      setLMU(option.some([updateEvent]));
    }
  }, [lastEvents, isWantedUpdate, augmentLabelIfKnown]);

  const [queue, setQueue] = useAtom(
    useFor === 'notifications' ? LIVE_NEW_NOTIFICATIONS : LIVE_NEW_MESSAGES,
  );
  useEffect(() => {
    let messages: CMContainer[] = [];
    if (queue.length > 0)
      setQueue(queue => {
        messages = queue;
        return [];
      });
    const updates = messages
      .flatMap(message => toMapUpdateOrUndefined(message, augmentLabelIfKnown))
      .filter((update): update is LiveMapUpdate => !!update && isWantedUpdate(update));

    updates.forEach(update => {
      lmDispatch(update);
    });
    setLMU(option.some(updates));
  }, [queue, setQueue, augmentLabelIfKnown, isWantedUpdate]);

  useEffect(() => {
    if (useFor === 'notifications') {
      setNotifications(notifications =>
        uniqBy([...notifications, ...lmState.notifications], 'notificationId'),
      );
    }
  }, [useFor, lmState.notifications, setNotifications]);

  return {
    state: lmState,
    lastUpdates: lastLMU,
    startDate,
  };
}
