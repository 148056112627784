import {
  AppRegistration as EditMany,
  Build,
  Clear,
  Delete,
  Edit,
  FilterList,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Header } from '../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import { METADATA_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { useMobile } from '../../util/useMobile';
import { truncationStyle } from '../Map/AssetRenderer';
import { SensedTriggeredEvent } from '../Map/Messages';
import { PortableAssetTool, ToolFilter, ToolType } from '../Map/types';
import { BulkTool } from './Tools';
import { ToolsFilterDialog } from './ToolsFilterDialog';

interface ToolsListProps {
  refresh: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  pageRef: MutableRefObject<number>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  openToolsFilterDialog: boolean;
  setOpenToolsFilterDialog: Dispatch<SetStateAction<boolean>>;
  availableTools: PortableAssetTool[];
  setAvailableTools: Dispatch<SetStateAction<PortableAssetTool[]>>;
  toolsCount: number;
  setToolsCount: Dispatch<SetStateAction<number>>;
  toolTypes: ToolType[];
  editingType: 'SINGLE' | 'BULK' | undefined;
  setEditingType: Dispatch<SetStateAction<'SINGLE' | 'BULK' | undefined>>;
  bulkList: PortableAssetTool[];
  setBulkList: Dispatch<SetStateAction<PortableAssetTool[]>>;
  singleUpdatedTool: PortableAssetTool | undefined;
  setSingleUpdatedTool: Dispatch<SetStateAction<PortableAssetTool | undefined>>;
  setBulkUpdatedTool: Dispatch<SetStateAction<BulkTool | undefined>>;
  activeTools: PortableAssetTool[];
}

const ToolsList: FC<ToolsListProps> = ({
  refresh,
  setRefresh,
  pageRef,
  page,
  setPage,
  isLoading,
  setIsLoading,
  openToolsFilterDialog,
  setOpenToolsFilterDialog,
  availableTools,
  setAvailableTools,
  toolsCount,
  setToolsCount,
  toolTypes,
  editingType,
  setEditingType,
  bulkList,
  setBulkList,
  singleUpdatedTool,
  setSingleUpdatedTool,
  setBulkUpdatedTool,
  activeTools,
}) => {
  const metadataUrl = useAtomValue(METADATA_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);
  const isMobile = useMobile();

  const [deleting, setDeleting] = useState<boolean>(false);
  const [toolFilter, setToolFilter] = useState<ToolFilter>({ perPage: 50 });

  const paginateTools = useCallback(
    async (filter: ToolFilter) => {
      let filters = `?perPage=${filter.perPage}`;
      Object.entries(filter).map(([key, value]) => {
        if (key !== undefined && value !== undefined && key !== 'perPage') {
          filters = filters.concat(
            `&${key}=${typeof value === 'object' ? value.id : value.toString()}`,
          );
        }
      });

      try {
        setIsLoading(true);
        const { tools, count } = (
          await axios.get<{ tools: PortableAssetTool[]; count: number }>(
            `${metadataUrl}/${cid}/${pid}/portableasset/tool/paginate/${
              deleting ? 1 : page
            }${filters}`,
            authedConfig,
          )
        ).data;

        const newList = [...(deleting ? [] : availableTools), ...tools];
        setAvailableTools(newList);
        setPage(prevPage => (count >= newList.length ? prevPage + 1 : prevPage));
        setToolsCount(count);
        setDeleting(false);
        setIsLoading(false);
      } catch (error) {
        setSaveNotification({
          id: SaveResult.FAIL,
          action: '',
          message: ((error as AxiosError).response as AxiosResponse).data.error,
        });
      }
    },
    [
      cid,
      pid,
      metadataUrl,
      authedConfig,
      setAvailableTools,
      page,
      deleting,
      setPage,
      availableTools,
      setToolsCount,
      setSaveNotification,
      setIsLoading,
    ],
  );

  useEffect(() => {
    if (!refresh) return;
    const fetchData = async () => {
      setIsLoading(true);
      paginateTools(toolFilter);
      setIsLoading(false);
    };
    fetchData();
    setIsLoading(false);
    setRefresh(false);
  }, [refresh, setRefresh, setIsLoading, paginateTools, toolFilter]);

  const deleteBulk = async () => {
    try {
      Promise.all(
        bulkList.map(async toDelete => {
          await axios.delete(
            `${metadataUrl}/${cid}/${pid}/portableasset/${toDelete.uuid}`,
            authedConfig,
          );
        }),
      ).then(() => {
        setAvailableTools(availableTools.filter(tool => !bulkList.find(del => del.id === tool.id)));
      });
      setBulkList([]);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Delete' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Delete',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const findActiveTool = (id: string): PortableAssetTool | undefined => {
    return activeTools.find(active => active.id === id);
  };

  return (
    <>
      <div
        style={{
          marginBottom: '24px',
          marginRight: '45px',
        }}
      >
        <Header icon={<Build />}>Existing tools</Header>
      </div>

      <Grid
        container
        direction="column"
        style={{
          marginLeft: '40px',
          marginBottom: '-30px',
          display: 'grid',
          gridTemplateColumns: '80px 80px 80px 80px 90px',
          overflowX: isMobile ? 'auto' : 'hidden',
          width: isMobile ? '300px' : '97%',
        }}
      >
        <Button
          onClick={() => {
            setOpenToolsFilterDialog(true);
          }}
        >
          <span style={{ fontSize: '10px' }}>Filter</span>
          <FilterList />
        </Button>

        <Button
          onClick={() => {
            setToolFilter({
              perPage: 50,
            });
            setAvailableTools([]);
            setPage(1);
            setRefresh(true);
          }}
        >
          <span style={{ fontSize: '10px' }}>Clear</span>
          <Clear />
        </Button>

        <Button
          style={{
            width: 'max-content',
            marginLeft: '5px',
          }}
          onClick={() => {
            setEditingType('SINGLE');
            if (bulkList.length === 0) {
              setSingleUpdatedTool(undefined);
            }
          }}
        >
          <span style={{ fontSize: '10px' }}>Edit</span>
          <Edit />
        </Button>

        <Button
          style={{
            marginLeft: '-5px',
          }}
          disabled={bulkList.length === 0}
          onClick={() => {
            setDeleting(true);
          }}
        >
          <span style={{ fontSize: '10px' }}>Delete</span>
          <Delete />
        </Button>

        {
          <Box
            style={{
              width: 'max-content',
              marginLeft: '15px',
              fontSize: 'x-small',
              color: editingType ? '#4CB8C4' : '#FFFFFF',
              opacity: editingType ? 1 : 0.3,
              fontFamily: `"Open Sans", "Montserrat", "Arial", sans-serif`,
              fontWeight: '500',
            }}
          >
            SELECT ALL
            <Checkbox
              style={{
                marginLeft: '-8px',
                color: editingType ? '#4CB8C4' : '#FFFFFF',
                opacity: editingType ? 1 : 0.3,
              }}
              disabled={!editingType}
              checked={availableTools.length > 0 && availableTools.length === bulkList.length}
              onChange={() => {
                setEditingType('BULK');
                availableTools.length === bulkList.length
                  ? setBulkList([])
                  : setBulkList(availableTools);
              }}
            />
          </Box>
        }
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          marginBottom: '15px',
          marginTop: '-26px',
          paddingRight: '40px',
          alignContent: 'end',
        }}
      >
        <Typography>{`${toolsCount} ${
          Number(toolsCount) === 1 ? 'result' : 'results'
        }`}</Typography>
      </Grid>

      <Grid
        id="tool-pagination-container"
        container
        justifyContent="left"
        style={{
          paddingTop: '5px',
          paddingBottom: '15px',
          marginLeft: '2%',
          border: 'inset #2D3748',
          maxWidth: '96%',
          width: 'fit-content, 30%',
          height: `1000px`,
          overflowX: 'hidden',
          overflowY: 'auto',
          flexFlow: 'column',
        }}
      >
        {/* Loading */}
        {isLoading && (
          <CircularProgress
            style={{
              width: '40px',
              height: '40px',
              position: 'absolute',
              marginTop: '20%',
              left: '50%',
            }}
          />
        )}

        {/* Tools List */}
        <TableContainer
          component={Paper}
          style={{
            width: '99.8%',
          }}
          onScroll={(e: React.UIEvent<HTMLDivElement>) => {
            const target = e.target as HTMLTextAreaElement;
            if (
              target.scrollTop + 50 > target.offsetTop * (page - 1) &&
              !isLoading &&
              toolsCount > availableTools.length
            ) {
              setRefresh(true);
            }
          }}
        >
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow
                style={{
                  height: '55px',
                }}
              >
                <TableCell style={{ fontSize: '15px' }}></TableCell>
                <TableCell style={{ fontSize: '15px' }} align="left">
                  ID
                </TableCell>
                <TableCell style={{ fontSize: '15px' }} align="left">
                  Label/Serial
                </TableCell>
                <TableCell style={{ fontSize: '15px' }} align="left">
                  Tool Type
                </TableCell>
                <TableCell style={{ fontSize: '15px' }} align="center">
                  Activity
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availableTools.map(tool => (
                <TableRow
                  key={tool.uuid}
                  sx={{
                    height: '55px',
                    fontSize: '15px',
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align="left" sx={{ width: '1em' }}>
                    <Checkbox
                      checked={!!bulkList.find(b => b.uuid === tool.uuid)}
                      disabled={!editingType}
                      onChange={() => {
                        const isChecked = bulkList.find(b => b.uuid === tool.uuid);
                        const updatedList = isChecked
                          ? bulkList.filter(b => b.uuid !== tool.uuid)
                          : [...bulkList, tool];
                        setBulkList(updatedList);
                        setEditingType(updatedList.length < 2 ? 'SINGLE' : 'BULK');
                        if (updatedList.length === 1) {
                          setSingleUpdatedTool(updatedList[0]);
                        }
                        if (updatedList.length === 0) {
                          setSingleUpdatedTool(undefined);
                        }
                      }}
                    />
                  </TableCell>
                  <Tooltip title={tool.id}>
                    <TableCell align="left">{tool.id}</TableCell>
                  </Tooltip>
                  <Tooltip title={tool.label ?? 'NO LABEL'}>
                    <TableCell
                      align="left"
                      style={{
                        color: tool.label ? '#fff' : '#924439',
                      }}
                    >
                      {tool.label ?? 'NO LABEL'}
                    </TableCell>
                  </Tooltip>
                  <Tooltip title={tool.tool}>
                    <TableCell align="left">{tool.tool}</TableCell>
                  </Tooltip>
                  <Tooltip title={findActiveTool(tool.id) ? 'ACTIVE' : ''}>
                    <TableCell
                      align="center"
                      style={{
                        color: findActiveTool(tool.id) ? '#4CB8C4' : '#fff',
                      }}
                    >
                      {findActiveTool(tool.id) ? 'ACTIVE' : '--'}
                    </TableCell>
                  </Tooltip>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Dialog open={!!deleting} onClose={() => setDeleting(false)}>
        <DialogTitle>{`Are you sure you want to delete ${
          bulkList.length > 1 ? 'these tools?' : 'this tool?'
        }`}</DialogTitle>
        <DialogActions>
          <Button
            onClick={async () => {
              if (deleting) {
                setDeleting(false);
                deleteBulk().then(() => {
                  setEditingType(undefined);
                  setSingleUpdatedTool(undefined);
                  setBulkUpdatedTool(undefined);
                });
              }
            }}
          >
            Yes
          </Button>
          <Button color="secondary" onClick={() => setDeleting(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openToolsFilterDialog}
        onClose={() => setOpenToolsFilterDialog(false)}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '70%',
            maxHeight: '80%',
            width: '600px',
            height: '80%',
          },
        }}
      >
        <ToolsFilterDialog
          setOpenToolsFilterDialog={setOpenToolsFilterDialog}
          setAvailableTools={setAvailableTools}
          toolFilter={toolFilter}
          setToolFilter={setToolFilter}
          toolTypes={toolTypes}
          setRefresh={setRefresh}
          setPage={setPage}
        ></ToolsFilterDialog>
      </Dialog>
    </>
  );
};

export default ToolsList;
