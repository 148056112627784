import { GridRowData } from '@material-ui/data-grid';
import {
  FastForward,
  FirstPage,
  FormatListBulleted,
  Pause,
  PlayArrow,
  Replay,
} from '@mui/icons-material';
import MapIcon from '@mui/icons-material/Map';
import { DateTimePicker, LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Fab,
  Grid,
  Paper,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { setSeconds, subHours } from 'date-fns';
import { map, option } from 'fp-ts/es6';
import {
  fromNullable,
  isNone,
  isSome,
  none,
  Option,
  some,
  toNullable,
  toUndefined,
} from 'fp-ts/es6/Option';
import { ordString } from 'fp-ts/es6/Ord';
import { pipe } from 'fp-ts/es6/pipeable';
import { useAtom, useSetAtom } from 'jotai';
import { Extent } from 'ol/extent';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  CenteredProgress,
  Header,
  Item,
  PaperBox,
  Sidebar,
  SubHeader,
} from '../../../Common/Sidebar';
import { LiveMapRenderer } from '../../../hooks/geomoby/LiveMapRenderer';
import { useGeomobyReplayStream } from '../../../hooks/geomoby/useGeomobyReplayStream';
import { useLiveMapLoader } from '../../../hooks/geomoby/useLiveMapLoader';
import {
  REPLAY_MESSAGES,
  REPLAY_NOW,
  REPLAY_PLAYING,
  REPLAY_SECONDS_PER_SECOND,
  REPLAY_START,
} from '../../../store/replayEvents';
import { RenderAssetState } from '../AssetRenderer';
import { SelectedAsset, getSearchSelectOptions, getTda, getStreamedTools } from '../LiveMap';
import { SidebarAndMap } from '../SidebarAndMap/SidebarAndMap';
import { REPLAY_NOTIFICATIONS, SEEN_REPLAY_NOTIFICATIONS } from '../../../store/notifications';
import { GeofenceSearchToolbar } from '../GeofenceSearchToolbar';
import { AssetLocation, LocationDisplayType, LocationSearchData } from '../Toolbar/LocationSearch';
import { DeviceOrTagLocation } from '../SidebarAndMap/Tiles/DeviceOrTagLocation';
import { MICROFENCE, MICROFENCE_LAYER_ID } from '../BeaconUtils';
import { Point } from 'ol/geom';
import { LiveMapSearch } from '../LiveMapSearch';
import { LiveMapFilter } from '../LiveMapFilter';
import {
  CreatorFilter,
  GeofenceFilter,
  LayerFilter,
  MicrofenceFilter,
  PortableAsset,
  PortableAssetTool,
  SearchType,
  ToolFilter,
  ToolType,
} from '../types';
import { Creator } from '../../../ContactTracing/useCreators';
import { Feature } from 'ol';
import { transform } from 'ol/proj';
import { AssetState, GeofenceEvent, SensedTriggeredEvent } from '../Messages';
import { TRACKED_DEVICE } from '../MapDefaults';

export function ReplayMap() {
  const replayStreamData = useGeomobyReplayStream();
  const replayStreamState = replayStreamData.state;
  const [streamedMicrofences, setStreamedMicrofences] = useState<SelectedAsset[]>([]);
  const [lastKnownAssetLocation, setLastKnownAssetLocation] = useState<AssetLocation | undefined>();
  const [layerId, setLayerId] = useState<string | undefined>();
  const [locationDisplay, setLocationDisplay] = useState<LocationDisplayType>();
  const [searchString, setSearchString] = useState<string | undefined>();
  const streamedAssets: (SelectedAsset & { prefix: string })[] = useMemo(
    () => [
      ...getSearchSelectOptions(replayStreamState.assets)
        .map(o => ({ ...o, prefix: TRACKED_DEVICE }))
        .filter(o => !streamedMicrofences.find(m => JSON.stringify(m.id) === JSON.stringify(o.id))),
      ...streamedMicrofences.map(o => ({ ...o, prefix: 'Microfence' })),
    ],
    [replayStreamState.assets, streamedMicrofences],
  );
  const streamedTools: SensedTriggeredEvent[] = useMemo(
    () => getStreamedTools(replayStreamState.assets),
    [replayStreamState],
  );

  const resetStateRef = useRef(() => {});

  const [selected, setSelected] = useState<Option<SelectedAsset>>(none);
  const [selectedGeofence, setSelectedGeofence] = useState<GridRowData | undefined>();
  const tdas = getTda({ selected, assets: replayStreamState.assets, selectedGeofence });

  useEffect(() => {
    if (
      (isSome(selected) &&
        streamedAssets.find(ts => JSON.stringify(ts.id) === JSON.stringify(selected.value.id))) ||
      streamedAssets.length === 0
    )
      return;
    //React Hook useEffect has a missing dependency: 'selected'. Either include it or remove the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamedAssets]);

  useEffect(() => {
    setSelectedGeofence(undefined);
  }, [searchString]);

  const lml = useLiveMapLoader();

  useEffect(() => {
    if (isSome(lml.result)) {
      const foundStreamedMicrofences: SelectedAsset[] = lml.result.value.microfences.map(
        ({ data }) => ({
          id: data.assetId,
          label: data.fenceName ?? data.fenceId,
        }),
      );
      setStreamedMicrofences(
        foundStreamedMicrofences.sort((a, b) => String(a.label).localeCompare(String(b.label))),
      );
    }
  }, [lml.result]);

  const [creatorFilter, setCreatorFilter] = useState<CreatorFilter>({ perPage: 50 });
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const [currentCenter, setCurrentCenter] = useState<number[] | undefined>();
  const [deselectFence, setDeselectFence] = useState<boolean>(false);
  const [geofenceFilter, setGeofenceFilter] = useState<GeofenceFilter>({ perPage: 50 });
  const [geomobyProperties, setGeomobyProperties] = useState<Record<string, string> | undefined>();
  const [microfenceFilter, setMicrofenceFilter] = useState<MicrofenceFilter>();
  const [layerFilter, setLayerFilter] = useState<LayerFilter | undefined>();
  const [locationSearchData, setLocationSearchData] = useState<LocationSearchData | undefined>();
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const [refreshSearch, setRefreshSearch] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<SearchType | undefined>();
  const [selectedBeacon, setSelectedBeacon] = useState<Creator | undefined>();
  const [selectedDevice, setSelectedDevice] = useState<Creator | undefined>();
  const [selectedMicrofence, setSelectedMicrofence] = useState<GridRowData | undefined>();
  const [selectedTool, setSelectedTool] = useState<PortableAssetTool | undefined>();
  const [selectedFromMap, setSelectedFromMap] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [toolFilter, setToolFilter] = useState<ToolFilter>({ perPage: 50 });
  const [toolTypes, setToolTypes] = useState<ToolType[]>([]);
  const [userExtent, setUserExtent] = useState<option.Option<Extent>>(option.none);

  const initialStartDateTime = useMemo(() => setSeconds(subHours(new Date(), 2), 0), []);
  const [startDateTime, setStartDateTime] = useState<Date | null>(initialStartDateTime);

  const [replayStart, setReplayStart] = useAtom(REPLAY_START);

  const [playing, setPlaying] = useAtom(REPLAY_PLAYING);

  const [secondsPerSecond, setSecondsPerSecond] = useAtom(REPLAY_SECONDS_PER_SECOND);

  const setReplayMessages = useSetAtom(REPLAY_MESSAGES);

  const setReplayNotifications = useSetAtom(REPLAY_NOTIFICATIONS);
  const setSeenReplayNotifications = useSetAtom(SEEN_REPLAY_NOTIFICATIONS);

  const [now, setNow] = useAtom(REPLAY_NOW);

  useEffect(() => {
    setNow(setSeconds(replayStart, 0));
  }, [replayStart, setNow]);

  useEffect(() => {
    setReplayStart(initialStartDateTime);
  }, [setReplayStart, initialStartDateTime]);

  useEffect(() => {
    if (!lastKnownAssetLocation) return;
    setLocationSearchData({
      coords: transform(
        [lastKnownAssetLocation.lon, lastKnownAssetLocation.lat],
        'EPSG:4326',
        'EPSG:3857',
      ),
      isLastKnownLocation: true,
    });
  }, [lastKnownAssetLocation]);

  return (
    <SidebarAndMap
      banner={
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          p={1}
          bgcolor="background.paper"
        >
          <Typography variant="h6" style={{ minWidth: 210 }}>
            {now.toLocaleString()}
          </Typography>
          <Fab color="secondary" size="small" onClick={() => setPlaying(value => !value)}>
            {playing ? <Pause /> : <PlayArrow />}
          </Fab>
        </Stack>
      }
      sidebar={
        <Sidebar>
          <Item>
            <Header icon={<Replay />}>Replay</Header>
            <Paper>
              <Box p={2}>
                <Item>
                  <SubHeader icon={<FirstPage />}>Set playback start</SubHeader>
                  <Stack direction="row" spacing={1}>
                    <DateTimePicker
                      disableFuture
                      value={startDateTime}
                      onChange={setStartDateTime}
                      label="Date and time"
                      renderInput={params => <TextField {...params} />}
                    />
                    <Tooltip title="The replay will be setup to start at this point">
                      <LoadingButton
                        onClick={() => {
                          setReplayMessages([]);
                          setReplayNotifications([]);
                          setSeenReplayNotifications({});
                          setPlaying(false);
                          if (startDateTime && !isNaN(startDateTime.getTime())) {
                            setReplayStart(setSeconds(startDateTime, 0));
                          }
                          resetStateRef.current();
                        }}
                        size="large"
                        color="secondary"
                        variant="contained"
                        disabled={!startDateTime || isNaN(startDateTime.getTime())}
                      >
                        Reset
                      </LoadingButton>
                    </Tooltip>
                  </Stack>
                  <SubHeader icon={<FastForward />}>Set playback speed</SubHeader>
                  <Box p={1}>
                    <Slider
                      value={Math.log2(secondsPerSecond)}
                      min={0}
                      valueLabelDisplay="auto"
                      valueLabelFormat={value => `${Math.pow(2, value)} seconds per second`}
                      max={7}
                      step={null}
                      marks={Array(8)
                        .fill(1)
                        .map((_, value) => ({
                          value,
                          label: `${Math.pow(2, value)}x`,
                        }))}
                      onChange={(_, value) => setSecondsPerSecond(Math.pow(2, Number(value)))}
                    />
                  </Box>
                </Item>
              </Box>
            </Paper>
          </Item>
          <Item>
            {isSome(lml.result) && (
              <LiveMapSearch
                layers={Array.from(lml.result.value.layers.entries())
                  .map(([key, lyr]) => {
                    return { id: key, name: lyr.name };
                  })
                  ?.sort((a, b) => a.name.localeCompare(b.name))}
                microfences={lml.result.value.microfences.sort((a, b) =>
                  a.feature.get('name').localeCompare(b.feature.get('name')),
                )}
                selectedBeacon={selectedBeacon}
                setSelectedBeacon={setSelectedBeacon}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                selectedGeofence={selectedGeofence}
                setSelectedGeofence={setSelectedGeofence}
                selectedMicrofence={selectedMicrofence}
                setSelectedMicrofence={setSelectedMicrofence}
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
                setToolTypes={setToolTypes}
                setExtent={setUserExtent}
                searchType={searchType}
                setSearchType={setSearchType}
                layerFilter={layerFilter}
                setLayerFilter={setLayerFilter}
                creatorFilter={creatorFilter}
                setCreatorFilter={setCreatorFilter}
                toolFilter={toolFilter}
                setToolFilter={setToolFilter}
                geofenceFilter={geofenceFilter}
                setGeofenceFilter={setGeofenceFilter}
                microfenceFilter={microfenceFilter}
                setMicrofenceFilter={setMicrofenceFilter}
                clearFilter={clearFilter}
                setClearFilter={setClearFilter}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                refreshSearch={refreshSearch}
                setRefreshSearch={setRefreshSearch}
                activeAssets={streamedAssets}
                activeTools={streamedTools}
                selectedAsset={selected}
                setSelectedAsset={setSelected}
                setLocationSearchData={setLocationSearchData}
                currentCenter={currentCenter}
                setLastKnownAssetLocation={setLastKnownAssetLocation}
                replayStartDateTime={startDateTime}
                locationDisplay={locationDisplay}
                setLocationDisplay={setLocationDisplay}
                setNavigateTo={setNavigateTo}
                setSelectedFromMap={setSelectedFromMap}
                setDeselectFence={setDeselectFence}
              ></LiveMapSearch>
            )}
            {isSome(lml.result) && (
              <LiveMapFilter
                searchType={searchType}
                layerFilter={layerFilter}
                setLayerFilter={setLayerFilter}
                creatorFilter={creatorFilter}
                setCreatorFilter={setCreatorFilter}
                toolFilter={toolFilter}
                setToolFilter={setToolFilter}
                geofenceFilter={geofenceFilter}
                setGeofenceFilter={setGeofenceFilter}
                microfenceFilter={microfenceFilter}
                setMicrofenceFilter={setMicrofenceFilter}
                selectedMicrofence={selectedMicrofence}
                clearFilter={clearFilter}
                setClearFilter={setClearFilter}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                setRefreshSearch={setRefreshSearch}
                toolTypes={toolTypes}
                setToolTypes={setToolTypes}
              ></LiveMapFilter>
            )}
            {selectedDevice && isNone(selected) && (
              <DeviceOrTagLocation
                heading={'Last Known Location'}
                deviceLocation={lastKnownAssetLocation}
              ></DeviceOrTagLocation>
            )}
            <>
              {(toNullable(tdas) ?? []).map((tda: AssetState) => {
                if (!tda) return;
                return RenderAssetState(fromNullable(tda), toUndefined(selected) === undefined);
              })}
            </>
          </Item>
        </Sidebar>
      }
      map={
        <LiveMapRenderer
          selected={selected}
          setSelected={setSelected}
          lsd={replayStreamData}
          selectedGeofence={selectedGeofence}
          setSelectedGeofence={setSelectedGeofence}
          selectedMicrofence={selectedMicrofence}
          setSelectedMicrofence={setSelectedMicrofence}
          setSelectedBeacon={setSelectedBeacon}
          setSelectedDevice={setSelectedDevice}
          setSelectedTool={setSelectedTool}
          userExtent={userExtent}
          setUserExtent={setUserExtent}
          onExtentChanged={lml.setBounds}
          lml={lml.result}
          fencesLoading={lml.loading}
          resetStyling={false}
          mapType={some('OUTDOOR_REPLAY_MAP')}
          resetStateRef={resetStateRef}
          showLocationTraces={true}
          setGeomobyProperties={setGeomobyProperties}
          searchType={searchType}
          setSearchType={setSearchType}
          locationSearchData={locationSearchData}
          setCurrentCenter={setCurrentCenter}
          locationDisplay={locationDisplay}
          navigateTo={navigateTo}
          setNavigateTo={setNavigateTo}
          selectedFromMap={selectedFromMap}
          setSelectedFromMap={setSelectedFromMap}
          deselectFence={deselectFence}
          setDeselectFence={setDeselectFence}
        />
      }
    />
  );
}
